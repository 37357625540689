import React from 'react';

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import JavaScript from '../assets/javascript.png';
import ReactImg from '../assets/react.png';
import Node from '../assets/node.png';
// import FireBase from '../assets/firebase.png';
// import AWS from '../assets/aws.png';
// import GitHub from '../assets/github.png';
import Python from '../assets/python.png';
import Tailwind from '../assets/tailwind.png';
import Mongo from '../assets/mongo.png';

import {
    faAngular,
    faCss3,
    faGitAlt,
    faHtml5,
    faJsSquare,
    faReact,
  } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "./s.scss"


const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full '>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-[#25a1bc] text-[#cdaa69] '>Skills</p>
              <p className='py-4 font-bold text-2xl text-gray-300 '> These are the technologies I've worked with</p>
          </div>

          <div className=' grid grid-cols-2 sm:grid-cols-2 gap-4 text-center py-0 w-full'>

            <div className='w-[700px] grid grid-cols-2 sm:grid-cols-3 gap-4 text-center py-2'>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500 '>
                    <img className='w-20 mx-auto '  src={HTML} alt="HTML icon" />
                    <p className='my-4 text-gray-200'>HTML</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={CSS} alt="CSS icon" />
                    <p className='my-4 text-gray-200'>CSS</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={JavaScript} alt="JS icon" />
                    <p className='my-4 text-gray-200'>JAVASCRIPT</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={ReactImg} alt="REACT icon" />
                    <p className='my-4 text-gray-200'>REACT</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={Python} alt="PY icon" />
                    <p className='my-4 text-gray-200'>PYTHON</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={Node} alt="NODE icon" />
                    <p className='my-4 text-gray-200'>NODE JS</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={Mongo} alt="MONGO icon" />
                    <p className='my-4 text-gray-200'>MONGO DB</p>
                </div>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mx-auto ' src={Tailwind} alt="TAILWIND icon" />
                    <p className='my-4 text-gray-200'>TAILWIND</p>
                </div>
            </div>
            
                <div className="cubespinner mt-20 ml-96 hidden md:flex ">
                    <div className="face1">
                    <FontAwesomeIcon icon={faAngular} color="#DD0031" />
                    </div>

                    <div className="face2">
                    <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                    </div>

                    <div className="face3">
                    <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                    </div>
                    
                    <div className="face4">
                    <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                    </div>

                    <div className="face5">
                    <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                    </div>

                    <div className="face6">
                    <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
                    </div>
                </div>             
          </div>



          

          
            

      </div>
    </div>
  );
};

export default Skills;
