import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import Animate from './Animate'


const Home = () => {

  return (

    <div name="home"  className='w-full h-screen bg-[#0a192f]'>

        {/* Container */}
        <div className='flex flex-col justify-center items-center '>
            <div className=' grid sm:grid-cols-2 gap-48 '>
                <div className='pl-40 py-44  '> 
                    <p className={'text-[#cdaa69] font-bold text-3xl  '} > Hi,my name is</p>  <br />
                    <h1 className='text-4xl sm:text-6xl font-bold text-[#ccd6f6] before: ' >Özcan Uncu</h1>
                    <h2 className='text-4xl sm:text-4xl font-bold text-[#8892b0] ' >I'm Frontend Developer</h2>
                    <p className='text-[#8892b0] py-4 max-w-[700px] font-bold '>Specializing in building (and occasionaly designing) exceptional digital experiences.Currently,I'm focused on building responsive full-stack web applications.</p>

                    <button className='text-[#8892b0] font-bold group border-2 px-6 py-3 my-2 flex items-center border-[#cdaa69] hover:bg-[#1d5565] hover:border-[#1d5565] '  >View Work
                        <span className='group-hover:rotate-90 duration-300'>
                            <HiArrowNarrowRight  className='ml-3 text-' />
                        </span>
                    </button>
                </div>
                <div className='py-24 hidden md:flex ' >
                    <Animate  ></Animate>
                </div>
            </div>
        </div>
    
    </div>

  )
}

export default Home