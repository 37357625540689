import React, { useEffect, useRef } from 'react'
import TagCloud from 'TagCloud'

const Tags = [
    'JavaScript',
    'CSS',
    'HTML',
    'Vscode',
    'Node',
    'React',
    'Python',
    'Linux',
    'Git',
    'Tailwind',
    'Firebase',
    'SASS',
    'JSON',
    'SQL',
]

function Animate() {
    const IsTagCloudLoaded = useRef(false)

    useEffect(() => {
        if (IsTagCloudLoaded.current) return

        TagCloud('.content', Tags, {
            radius: 250,
            maxSpeed: 'fast',
            initSpeed: 'fast',
            direction: 135,
            keep: true,
        })

        IsTagCloudLoaded.current = true
    }, [])

    return (
        <div className='flex flex-col'>
            <div className=' pl font-bold text-white'>
                <div className='content' />
            </div>
        </div>
    )
}

export default Animate