import React from 'react';
import Odemy from '../assets/Odemy.png';
import Pcat from '../assets/pcat.png';
import Ozflix from '../assets/ozflix.png';
import Ozrest from '../assets/Ozrest.png';
import Crypto from '../assets/crypto.png';
import Bbad from '../assets/bbad.png';





const Work = () => {
  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-[#25a1bc] text-[#cdaa69]'>
            Work
          </p>
          <p className='py-6 font-bold text-2xl text-gray-300'> Check out some of my recent work</p>
        </div>

{/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Odemy})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div '
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider '>
                Odemy
              </span>
              <div className='pt-8 text-center'>
                <a target="_blank" rel="noopener noreferrer"  href='https://odemy-app.herokuapp.com/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 hover:border-[#cdaa69] font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a target="_blank" rel="noopener noreferrer"  href='https://github.com/busozz/Node-Odemy-Project'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700  font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${Pcat})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Photo Creator
              </span>
              <div className='pt-8 text-center'>
                <a target="_blank" rel="noopener noreferrer"  href='https://pcat-application.herokuapp.com/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a target="_blank" rel="noopener noreferrer"  href='https://github.com/busozz/Photo-App'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Ozflix})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Ozflix
              </span>
              <div className='pt-8 text-center'>
                <a target="_blank" rel="noopener noreferrer"  href='https://busozz.github.io/React-Ozflix-App/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a target="_blank" rel="noopener noreferrer"  href='https://github.com/busozz/React-Ozflix-App'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${Crypto})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Crypto Tracker
              </span>
              <div className='pt-8 text-center'>
                <a target="_blank" rel="noopener noreferrer"  href='https://busozz.github.io/React-Crypto-Tracker/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a target="_blank" rel="noopener noreferrer"  href='https://github.com/busozz/React-Crypto-Tracker'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${Ozrest})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Ozrest
              </span>
              <div className='pt-8 text-center'>
                <a target="_blank" rel="noopener noreferrer"  href='https://busozz.github.io/React-Restaurant-App/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a target="_blank" rel="noopener noreferrer"  href='https://github.com/busozz/React-Restaurant-App'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${Bbad})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Movie App
              </span>
              <div className='pt-8 text-center'>
                <a target="_blank" rel="noopener noreferrer"  href='https://busozz.github.io/React-Movie-App/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Demo
                  </button>
                </a>
                <a target="_blank" rel="noopener noreferrer"  href='https://github.com/busozz/React-Movie-App'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
