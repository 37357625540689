import {useState} from 'react'
import Logo from "../assets/bo7.png"
import {FaBars,FaTimes,FaLinkedin,FaGithub} from "react-icons/fa"
import {HiOutlineMail} from "react-icons/hi"
import {BsFillPersonLinesFill} from "react-icons/bs"
import { Link } from 'react-scroll'


const Navbar = () => {

  const [nav,setNav] = useState(false)
  const handleClick = () => {
    setNav(!nav)
  }


  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-[#7c2bc2] font-bold '> 
      
      <div>
        <Link to='home' className='cursor-pointer ' smooth={true}  duration={500}>
          <img src={Logo} alt="Logo" className=" ml-[-10px] pt-10 w-[100px]  " />
        </Link>
      </div>

    {/* menu */}


        <ul className='hidden md:flex navbar-color ' >

          <li>
            <Link to="home" smooth={true}  duration={500} activeClass=''>
              Home
            </Link>
          </li>

          <li>
            <Link  to="about" smooth={true}  duration={500} >
              About
            </Link>
          </li>

          <li>
            <Link  to="skills" smooth={true}  duration={500} >
              Skills
            </Link>
          </li>

          <li>
            <Link  to="work" smooth={true}  duration={500} >
              Work
          </Link>
          </li>

          <li>
            <Link  to="contact" smooth={true}  duration={500} >
              Contact
            </Link>
          </li>

        </ul>

    {/* hamburger */}
      <div  onClick={handleClick} className='md:hidden z-10 cursor-pointer'>
      {
        !nav ? <FaBars/> : <FaTimes/>
      }
      </div>

    {/* mobile menu */}
        <ul className={!nav ? "hidden" : "absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center"}>
          <li className='py-6 text-4xl pb-12 '> 
          <Link onClick={handleClick} spy={true} to="home" smooth={true}  duration={500} >
              Home
            </Link></li>
          <li className='py-6 text-4xl pb-12'>
            <Link onClick={handleClick} spy={true} to="about" smooth={true}  duration={500} >
              About
            </Link></li>
          <li className='py-6 text-4xl pb-12'>
          <Link onClick={handleClick} spy={true} to="skills" smooth={true}  duration={500} >
              Skills
            </Link></li>
          <li className='py-6 text-4xl pb-12'>
          <Link onClick={handleClick} spy={true} to="work" smooth={true}  duration={500} >
              Work
          </Link></li>
          <li className='py-6 text-4xl pb-12'><Link onClick={handleClick} spy={true} to="contact" smooth={true}  duration={500} >
              Contact
            </Link></li>
        </ul>
      

    {/* social icons  */}
      <div className='hidden lg:flex fixed flex-col top-[35%] left-0 '>
        <ul>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600 ' >
            <a className='flex justify-between items-center w-full text-gray-300 ' href="https://www.linkedin.com/in/ozcanuncu/">
              Linkedin <FaLinkedin size={30}/>
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333333] '>
            <a className='flex justify-between items-center w-full text-gray-300 ' href="https://github.com/busozz">
              Github <FaGithub size={30}/>
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-red-700 '>
            <a className='flex justify-between items-center w-full text-gray-300 ' href="mailto:uncu991@gmail.com">
              Gmail <HiOutlineMail size={30}/>
            </a>
          </li>
          <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-purple-500 '>
            <a className='flex justify-between items-center w-full text-gray-300 ' href="/">
              Resume <BsFillPersonLinesFill size={30}/>
            </a>
          </li>
        </ul>
      </div>


    </div>
  )
}

export default Navbar